import * as React from "react"
import { useLocation } from "@reach/router"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import { TagManagerContext } from "../../core/contexts/tagManager"
import { ContactFormContextProvider } from "../../core/contexts/contactForm"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Card from "../../components/card/card"
import Divider from "../../components/divider/divider"
import TextButton from "../../components/buttons/textButton"
import ElevatedButton from "../../components/buttons/elevatedButton"
import ContactForm from "../../components/contactForm/contactForm"

const description =
  "Connecting the user, the designer, the business, and the developer to invent better digital products and experiences."

const DevelopingNewDigitalProductsPage = () => {
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "page",
    })
  }

  return (
    <Layout>
      <Seo
        title="Solutions | Developing New Digital Products"
        description={description}
      />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Developing New Digital Products</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-2">
            <h6 className="mw-800">
              For many companies, the digital experience — how consumers engage
              with, navigate through, and react to a company’s digital products,
              services, and platforms — is their business.
            </h6>
            <TextButton
              label="Source: PwC | Experience matters: The five commandments of digital product management"
              icon={faArrowRight}
              isExternalLink={true}
              hasNoPaddingStart={true}
              href="https://www.strategyand.pwc.com/gx/en/insights/2017/experience-matters.html"
            />
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Digital experiences are digital products</h6>
          <p>
            We understand digital isn’t just about technology – it’s about what
            technology can enable. It involves new ways of solving problems,
            creating unique experiences and accelerating business performance.
            It&apos;s about your business thriving at the intersection of human
            and technology to enable your tomorrow, today.
          </p>
        </div>
      </section>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-2">
            <h6 className="mw-800">
              Every digital experience platform needs a content component,
              because content is the heart of every digital experience
            </h6>
            <TextButton
              label="Source: Contentful | What’s a digital experience platform?"
              icon={faArrowRight}
              isExternalLink={true}
              hasNoPaddingStart={true}
              href="https://www.contentful.com/blog/2021/07/12/whats-a-digital-experience-platform/"
            />
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Digital experiences are digital content and more</h6>
          <p>
            A typical digital experience platform will include a content
            platform, analytics, personalization, digital asset management and
            customer data management. This would also include your typical
            channels such as websites, web apps, native mobile apps, and more.
          </p>
        </div>
      </section>
      <div className="container mt-5">
        <Divider />
      </div>
      <section className="container pt-5 pb-5">
        <Card isPrimary={true} canHover={false}>
          <div className="pt-2 pb-2">
            <div className="row justify-space-between align-center">
              <h6 className="mb-3">
                Need to create an effective content ecosystem?
              </h6>
              <div
                onClick={() =>
                  onLinkClick({
                    label: "Explore Content Creation",
                    href: "/solutions/creating-a-content-ecosystem",
                  })
                }
              >
                <ElevatedButton
                  label="Explore Content Creation"
                  href="/solutions/creating-a-content-ecosystem"
                />
              </div>
            </div>
          </div>
        </Card>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="mt-5">
        <Card canHover={false} isPrimary={true} isPadded={false}>
          <div className="container pt-2 pb-4">
            <h6 className="mw-800">How Do We Develop New Digital Products?</h6>
            <p className="mw-800">
              Our digital products are first and foremost digital experiences by
              DNA. We follow PwC&apos;s five commandments based on the
              determined imperatives for successful product and service
              management in the digital age.
            </p>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Become a customer zealot</h6>
                  <p className="m-0">
                    Create value through entertaining, useful, efficient digital
                    customer experiences
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">Pilot and learn continuously</h6>
                  <p className="m-0">
                    Develop, iterate, test, and deploy new features and
                    functions quickly, and kill failures fast.
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    Judge success with the right metrics
                  </h6>
                  <p className="m-0">
                    Determine what creates value, and monitor the specific
                    customer metrics that help add value.
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    Gain expertise in data and analytics
                  </h6>
                  <p className="m-0">
                    Capture as much data as you can, but hae clear strategies to
                    analyze it, too.
                  </p>
                </Card>
              </div>
              <div className="col-xs-12 col-md-6">
                <Card isPrimary={false} canHover={false}>
                  <h6 className="subtitle2">
                    Develop globally, customize locally
                  </h6>
                  <p className="m-0">
                    Build the underlying online infrastructure once, and then
                    tailor it to the specific needs and expectations of each
                    market.
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <section className="container mt-5">
        <Card isPrimary={false} canHover={false}>
          <h6 className="mw-800 m-0">
            Customers are demanding faster, more personalised user experiences.
            Business processes that once were cumbersome can now be automated.
            Operational efficiency has become paramount.
          </h6>
        </Card>
      </section>
      <section className="container mt-5">
        <div className="mw-800">
          <h6>Results in five steps</h6>
          <p>We develop new digital products using the following steps:</p>
          <ul>
            <li>Define Objectives</li>
            <li>Research</li>
            <li>Brainstorming &amp; Creative Ideas</li>
            <li>Deployment</li>
            <li>Measure &amp; Iterate</li>
          </ul>
        </div>
      </section>
      <section className="container mt-5">
        <p>Required fields are marked with an asterisk (*).</p>
        <Card canHover={false}>
          <h6 className="mb-4 mw-800">
            Let&apos;s talk about how we can work as a team to build and
            innovate on digital products for your business.
          </h6>
          <ContactFormContextProvider>
            <ContactForm
              defaultSubject="product_development"
              subjects={[
                { value: "product_development", label: "Product Development" },
              ]}
            />
          </ContactFormContextProvider>
        </Card>
      </section>
    </Layout>
  )
}

export default DevelopingNewDigitalProductsPage
